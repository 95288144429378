import { ResponseGeneric, ResponsePaginate } from '../types/responses.type';
import { responseError, responseErrorGeneric } from '../utils/types';
import axios from '../utils/axiosConfig';

export const getAll = async <T>(
  path: string,
  perPage = 25,
  page = 1,
  search?: string,
  element?: keyof T
): Promise<ResponsePaginate<T>> => {
  try {
    const response = await axios<ResponsePaginate<T>>({
      method: 'get',
      url: `${path}?page=${page}&perPage=${perPage}${
        search ? `&filter[${element as string}][substring]=${search}` : ''
      }`,
    });
    return response;
  } catch (error) {
    return responseError;
  }
};

export const getGeneric = async <T>(
  path: string
): Promise<ResponseGeneric<T>> => {
  try {
    const response = await axios<ResponseGeneric<T>>({
      method: 'get',
      url: `${path}`,
    });
    return response;
  } catch (error) {
    return responseErrorGeneric;
  }
};

export const postGeneric = async <T>(
  path: string,
  data: T
): Promise<ResponseGeneric<T>> => {
  try {
    const response = await axios<ResponseGeneric<T>>({
      method: 'post',
      url: `${path}`,
      data: data,
    });
    return response;
  } catch (error) {
    return responseErrorGeneric;
  }
};

export const patchGeneric = async <T>(
  path: string,
  data: T
): Promise<ResponseGeneric<T>> => {
  try {
    const response = await axios<ResponseGeneric<T>>({
      method: 'patch',
      url: `${path}`,
      data: data,
    });
    return response;
  } catch (error) {
    return responseErrorGeneric;
  }
};
