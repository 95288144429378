import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from '../components/pages/dashboard';

import Home from '../components/pages/home';
import Login from '../components/pages/login';
import RegisterUsers from '../components/pages/registerUsers';
import UserValidate from '../components/pages/userValidate';
import { User } from '../types/user.type';
import { checkIsLogged } from '../utils/isLogged';
import ProtectedRoute from './protectedRoute';

export type UserContextType = {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  isLogged: boolean;
  setIsLogged: React.Dispatch<React.SetStateAction<boolean>>;
  loadingLogin: boolean;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultUser = {
  id: 26,
  firstName: 'Mailer',
  lastName: 'Martinez',
  phone: '3192432568',
  identification: '1039100351',
  createdAt: '2023-02-09T00:05:37.000Z',
  updatedAt: '2023-02-09T00:05:37.000Z',
  DocumentTypeId: 1,
  DocumentType: {
    id: 1,
    name: 'Cédula de Ciudadanía',
  },
};
export const AppContext = createContext<UserContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUser: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsLogged: () => {},
  isLogged: false,
  user: defaultUser,
  loadingLogin: true,
  showModal: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowModal: () => {},
});
const Router = () => {
  const [user, setUser] = useState<User>(defaultUser);
  const [isLogged, setIsLogged] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getisLogged();
  }, [isLogged]);

  const getisLogged = async () => {
    const response = await checkIsLogged();
    if (response) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
    setLoadingLogin(false);
  };

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        isLogged,
        setIsLogged,
        loadingLogin,
        showModal,
        setShowModal,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<RegisterUsers />} />
          <Route path="/validate" element={<UserValidate />} />
          <Route path="/demo" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export default Router;
