import { TDocumentType } from '../types/documentType.type';
import { getAll } from './api.service';

export const getAllDocumentTypes = async (
  perPage = 25,
  page = 1,
  search = '',
  columnSearch: keyof TDocumentType = 'name'
) => {
  const response = await getAll<TDocumentType>(
    'util/documentTypes',
    perPage,
    page,
    search,
    columnSearch
  );
  return response;
};
