export const numberToMoney = (number: number) => {
  return new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0,
  }).format(number);
};

export const deleteStrings = (string: string) => {
  return Number(string.replace(/[$.]/g, ''));
};
