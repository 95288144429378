import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import imageError from '../../../assets/icons/icon_warning.svg';
import iconDown from '../../../assets/icons/icon_down.svg';
import iconUp from '../../../assets/icons/icon_up.svg';
import iconCheck from '../../../assets/icons/icon_check.svg';
import { deleteStrings, numberToMoney } from '../../../utils/locations';

type TOptions = {
  id: number;
  name: string;
};

type TProps = {
  type:
    | 'text'
    | 'number'
    | 'email'
    | 'password'
    | 'textarea'
    | 'money'
    | 'select'
    | 'radio'
    | 'checkbox';
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  label?: string;
  name?: string;
  helpText?: string;
  error?: string;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  maxLength?: number;
  minLength?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setError?: any;
  options?: TOptions[];
  onSelect?: (option: number | undefined) => void;
  onSelectCheckbox?: (options: number[]) => void;
};

const Input = ({
  type,
  className,
  placeholder = 'Escriba aquí',
  disabled,
  value,
  label,
  name,
  helpText,
  error,
  onChange,
  register,
  readOnly,
  maxLength,
  minLength,
  setValue,
  setError,
  options,
  onSelect,
  onSelectCheckbox,
}: TProps) => {
  const [moneyNumber, setMoneyNumber] = useState('');
  const [stateSelect, setStateSelect] = useState(false);
  const [optionSelected, setOptionSelected] = useState<TOptions>();
  const [optionsSelectedCheckbox, setOptionsSelectedCheckbox] = useState<
    TOptions[]
  >([]);

  const parseMoney = (number: string) => {
    const newNumber = deleteStrings(number);
    if (!Number.isNaN(newNumber)) {
      setError(register.name, '');
      setValue ? setValue(register.name, newNumber) : null;
      const newParse = numberToMoney(newNumber);
      setMoneyNumber(newParse);
    }
  };

  const handleOpenSelect = () => {
    setStateSelect(!stateSelect);
  };

  const handleSelect = (option: TOptions) => {
    if (optionSelected?.id !== option.id) {
      setError ? setError(register.name, '') : null;
      setOptionSelected(option);
      if (setValue && register) {
        setValue(register.name, option.id);
      }
      if (onSelect) {
        onSelect(option.id);
      }
    } else {
      setOptionSelected(undefined);
      if (setValue && register) {
        setValue(register.name, '');
      }
      if (onSelect) {
        onSelect(undefined);
      }
    }
    setStateSelect(false);
  };

  const handleSelectCheckbox = (option: TOptions) => {
    const dummieSelecteds = [...optionsSelectedCheckbox];
    const index = dummieSelecteds.findIndex((e) => e.id === option.id);
    if (index >= 0) {
      //Quitarlo del array si existe
      dummieSelecteds.splice(index, 1);
    } else {
      //Agregarlo al array si no existe
      dummieSelecteds.push(option);
    }
    setOptionsSelectedCheckbox(dummieSelecteds);
    const dummieSend = dummieSelecteds.map((e) => e.id);
    if (setValue && register) {
      setValue(register.name, dummieSend);
    }
    if (onSelectCheckbox) {
      onSelectCheckbox(dummieSend);
    }
  };

  useEffect(() => {
    if ((type === 'select' || type === 'radio') && value && options) {
      const elementSelected = options.find((e) => e.id === Number(value));
      setOptionSelected(elementSelected);
      if (register) {
        setValue(register.name, elementSelected?.id);
      }
    }
  }, [type, value, options, register]);

  return (
    <>
      <div className={styles.input__group}>
        <label className={styles.input__label}>
          {label ? (
            <span className={styles.input__label__text}>{label}</span>
          ) : (
            ''
          )}
          {type === 'text' ||
          type === 'number' ||
          type === 'email' ||
          type === 'password' ? (
            <input
              className={`${styles.input} ${error ? styles.input__error : ''} ${
                className || ''
              }`}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              value={value}
              name={name}
              onChange={onChange}
              readOnly={readOnly}
              maxLength={maxLength}
              minLength={minLength}
              autoComplete={type === 'password' ? 'on' : 'off'}
              {...register}
            />
          ) : type === 'textarea' ? (
            <textarea
              className={`${styles.input} ${styles.input__textArea} ${
                error ? styles.input__error : ''
              } ${className || ''}`}
              placeholder={placeholder}
              disabled={disabled}
              name={name}
              onChange={onChange}
              readOnly={readOnly}
              maxLength={maxLength}
              minLength={minLength}
              {...register}
            >
              {value}
            </textarea>
          ) : type === 'money' ? (
            <>
              <input
                className={`${styles.input} ${
                  error ? styles.input__error : ''
                } ${className || ''}`}
                type="text"
                placeholder={placeholder}
                disabled={disabled}
                onChange={({ target }) => {
                  parseMoney(target.value);
                }}
                value={moneyNumber}
                readOnly={readOnly}
                maxLength={maxLength}
                minLength={minLength}
                inputMode={'numeric'}
              />
              <input
                className={styles.input__hidden}
                type="text"
                name={name}
                {...register}
              />
            </>
          ) : type === 'select' ? (
            <section className={`${styles.select__container}`}>
              <div
                className={`${styles.select} ${
                  stateSelect ? styles.select__open : ''
                } ${className}`}
                onClick={handleOpenSelect}
              >
                <span>
                  {optionSelected?.name || placeholder || 'Seleccione'}
                </span>
                <figure className={styles.select__figure}>
                  <img src={stateSelect ? iconUp : iconDown} alt="Seleccione" />
                </figure>
              </div>
              {stateSelect ? (
                <div className={styles.select__options}>
                  {!options?.length ? (
                    <div className={`${styles.select__option}`}>
                      <span>No hay opciones</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {options?.map((option, index) => (
                    <div
                      key={index}
                      className={`${styles.select__option} ${
                        optionSelected?.id === option.id
                          ? styles.select__option__selected
                          : ''
                      }`}
                      onClick={() => {
                        handleSelect(option);
                      }}
                    >
                      <span>{option.name}</span>
                      {optionSelected?.id === option.id ? (
                        <figure className={styles.select__figure}>
                          <img src={iconCheck} alt="Check" />
                        </figure>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </section>
          ) : type === 'radio' ? (
            <div className={styles.radio__group}>
              {options?.map((optionsRadio, index) => (
                <label key={index} className={styles.radio__label}>
                  <input
                    type={'radio'}
                    value={optionsRadio.id}
                    className={styles.radio__input}
                    checked={optionSelected?.id === optionsRadio.id}
                    onChange={() => {
                      handleSelect(optionsRadio);
                    }}
                    disabled={disabled}
                    name={register ? register.name : name}
                  />
                  <i></i>
                  <span className={styles.input__label__text}>
                    {optionsRadio.name}
                  </span>
                </label>
              ))}
            </div>
          ) : type === 'checkbox' ? (
            <div className={styles.checkbox__group}>
              {options?.map((option, index) => (
                <label key={index} className={styles.checkbox__label}>
                  <input
                    type="checkbox"
                    value={option.id}
                    className={styles.checkbox__input}
                    checked={optionsSelectedCheckbox.some(
                      (e) => e.id === option.id
                    )}
                    onChange={() => {
                      handleSelectCheckbox(option);
                    }}
                    disabled={disabled}
                    name={register ? register.name : name}
                  />
                  <i></i>
                  <span className={styles.input__label__text}>
                    {option.name}
                  </span>
                </label>
              ))}
            </div>
          ) : (
            <></>
          )}
          {error ? (
            <figure
              className={
                label ? styles.imageError : styles.imageErrorWithoutLabel
              }
            >
              <img src={imageError} alt="error" />
            </figure>
          ) : (
            ''
          )}
        </label>
        {helpText ? (
          <span className={styles.input__helpText}>{helpText}</span>
        ) : (
          ''
        )}
        {error ? <span className={styles.input__errorText}>{error}</span> : ''}
      </div>
      {stateSelect ? (
        <div
          className={styles.portal}
          onClick={() => {
            setStateSelect(false);
          }}
        ></div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Input;
