import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../atoms/button';
import Input from '../../atoms/input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import es from 'yup-es';

type FormValues = {
  example: string;
  description: string;
  money: string;
  product: string;
  gender: string;
  hobbies: string[];
};

yup.setLocale(es);

const schema = yup
  .object({
    example: yup.string().required(),
    description: yup.string().required().max(5),
    money: yup.number().min(1000),
    product: yup.string().required(),
    gender: yup.string().required(),
    hobbies: yup.array().required(),
  })
  .required();

const options = [
  {
    id: 1,
    name: 'Mailer',
  },
  {
    id: 2,
    name: 'Juan',
  },
];

const optionsGender = [
  {
    id: 1,
    name: 'Masculino',
  },
  {
    id: 2,
    name: 'Femenino',
  },
];

const optionsHobbies = [
  {
    id: 1,
    name: 'Futbol',
  },
  {
    id: 2,
    name: 'Baloncesto',
  },
];

const Form = () => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: 'Tal cosa',
      gender: '1',
      hobbies: ['1']
    },
  });

  const submit: SubmitHandler<FormValues> = (data) => {
    console.log(data);
  };
  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Input
        type="text"
        label="Ejemplo"
        register={register('example')}
        error={errors.example?.message}
      />
      <Input
        type="select"
        label="Producto"
        register={register('product')}
        error={errors.product?.message}
        setValue={setValue} //Send for select
        setError={setError} //Send for select
        options={options} //Send for Select
        // value={'1'} //If value is preselected
      />
      <Input
        type="radio"
        label="Género"
        register={register('gender')}
        error={errors.gender?.message}
        setValue={setValue} //Send for radio
        setError={setError} //Send for radio
        options={optionsGender} //Send for Radio
      />
      <Input
        type="money"
        label="Dinero"
        register={register('money')}
        error={errors.money?.message}
        setValue={setValue} //Send for money
        setError={setError} //Send for money
      />
      <Input
        type="textarea"
        label="Descripción"
        placeholder="Escriba algo"
        maxLength={5}
        register={register('description')}
        error={errors.description?.message}
      />
      <Input
        type="checkbox"
        label="Hobbies Favoritos"
        register={register('hobbies')}
        error={errors.hobbies?.message}
        setValue={setValue} //Send for radio
        setError={setError} //Send for radio
        options={optionsHobbies} //Send for Radio
        // value={'1'} //If value is preselected
      />
      <Button type="submit" theme="success">
        Probar
      </Button>
    </form>
  );
};

export default Form;
