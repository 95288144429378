import { ResponseGeneric } from '../types/responses.type';
import { FormValuesLogin, TResponseLogin } from '../types/user.type';
import axios from '../utils/axiosConfig';

export const doLogin = async (
  data: FormValuesLogin
): Promise<ResponseGeneric<TResponseLogin>> => {
  try {
    const response = await axios<ResponseGeneric<TResponseLogin>>({
      method: 'post',
      url: 'auth/login',
      data,
    });
    if (response.data?.token) {
      localStorage.setItem('token', JSON.stringify(response.data.token));
    }
    return response;
  } catch (error) {
    return {
      message: 'Usuario o contraseña incorrectos',
    };
  }
};

export const doLogout = () => {
  localStorage.clear();
};
