export const responseError = {
  message: 'Error',
  meta: {
    isLast: true,
    page: 1,
    perPage: 25,
    totalItems: 0,
    totalPages: 1,
  },
  data: [],
};

export const responseErrorGeneric = {
  message: 'Error',
};