import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '.';
import Loading from '../components/pages/loading';

type TProps = {
  children: React.ReactNode;
};

const ProtectedRoute = ({ children }: TProps) => {
  const { isLogged, loadingLogin } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loadingLogin) {
      if (!isLogged) {
        navigate('login');
      }
    }
  }, [isLogged, loadingLogin]);

  return <>{isLogged ? children : <Loading />}</>;
};

export default ProtectedRoute;
