import React from 'react';
import style from './style.module.scss';

type TProps = {
  children: React.ReactNode;
  theme?: 'primary' | 'secondary' | 'warning' | 'error' | 'success';
  size?: 'lg' | 'md' | 'sm';
};

const Badge = ({ children, theme = 'primary', size = 'sm' }: TProps) => {
  return (
    <span className={style.badge} data-theme={theme} data-size={size}>
      {children}
    </span>
  );
};

export default Badge;
