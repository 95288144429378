import React, { useContext } from 'react';
import Title from '../../atoms/title';
import style from './style.module.scss';
import logoImg from '../../../assets/images/oneapp_logo1.png';
import userImg from '../../../assets/images/user.png';
import MegaMenu from '../../molecules/mega-menu';
import Menu from '../../molecules/menu';
import { doLogout } from '../../../services/login.service';
import { AppContext } from '../../../routes';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const { setIsLogged, user } = useContext(AppContext);

  const navigate = useNavigate();

  const handleLogout = () => {
    doLogout();
    setIsLogged(false);
  };

  const handleHome = () => {
    navigate('/');
  };

  return (
    <div className={style.mega}>
      <aside className={style.menu__mobile}>
        <figure className={style.menu__figure}>
          <img src={logoImg} alt="Logo" className={style.menu__logo} />
        </figure>
        <span
          className={`material-icons ${style.icon__home}`}
          onClick={handleHome}
        >
          home
        </span>
        <figure className={style.menu__figure__user}>
          <img
            src={userImg}
            alt="Logo"
            className={style.menu__userImg}
            onClick={handleLogout}
          />
        </figure>
      </aside>
      <aside className={style.header}></aside>
      <main className={style.main}>
        <aside className={style.menu}>
          <figure className={style.menu__figure}>
            <img
              src={logoImg}
              alt="Logo"
              className={style.menu__logo}
              onClick={handleHome}
            />
          </figure>
          <Menu />
          <div className={style.menu__user}>
            <figure className={style.menu__figure__user}>
              <img src={userImg} alt="Logo" className={style.menu__userImg} />
            </figure>
            <div className={style.menu__user__information}>
              <div
                className={style.menu__user__name}
              >{`${user.firstName} ${user.lastName}`}</div>
            </div>
            <span
              className={`material-icons ${style.icon__logout}`}
              onClick={handleLogout}
            >
              logout
            </span>
          </div>
        </aside>
        <section className={style.container}>
          <div className={style.welcome}>
            <Title>Bienvenido:</Title>
            <Title as="h3">{`${user.firstName} ${user.lastName}`}</Title>
          </div>
          <MegaMenu />
        </section>
      </main>
    </div>
  );
};

export default Dashboard;
