import React from 'react';
import styles from './style.module.scss';

type TProps = {
  theme?: 'primary' | 'secondary' | 'warning' | 'error' | 'success' | 'neutral';
  type?: 'button' | 'submit' | 'reset';
  size?: 'lg' | 'md' | 'sm';
  className?: string;
  disabled?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
};

const Button = ({
  children,
  type = 'button',
  theme = 'primary',
  size = 'md',
  className,
  disabled,
  onClick,
}: TProps) => {
  return (
    <button
      className={`${styles.button} ${className || ''}`}
      type={type}
      data-theme={theme}
      data-size={size}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
