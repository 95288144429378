import React from 'react';
import MenuItem from './menuItem';
import style from './style.module.scss';

const MegaMenu = () => {
  return (
    <section className={style.container}>
      <MenuItem name="Mis Productos" />
      <MenuItem name="Mis Negocio" />
      <MenuItem name="Vender" />
    </section>
  );
};

export default MegaMenu;
