import React from 'react';
import style from './style.module.scss';

const Loading = () => {
  return (
    <section className={style.loading}>
      <div className={style.circle}></div>
      <div className={style.middle}></div>
      <div className={style.circle2}></div>
    </section>
  );
};

export default Loading;
