import React, { useContext, useEffect } from 'react';
import Button from '../../atoms/button';
import Input from '../../atoms/input';
import styles from './style.module.scss';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TOtp, Totpverify } from '../../../types/otp.type';
import axios from '../../../utils/axiosConfig';
import { ResponseGeneric } from '../../../types/responses.type';
import { useForm } from 'react-hook-form';
import { FormValuesValidate, User } from '../../../types/user.type';
import { schemaPassword } from '../../../schemas/register';
import { yupResolver } from '@hookform/resolvers/yup';
import { doLogin } from '../../../services/login.service';
import { AppContext } from '../../../routes';

const UserValidate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser, setIsLogged, isLogged, loadingLogin } =
    useContext(AppContext);
  const [resend, setResend] = useState(false);
  const [minuts, setMinuts] = useState(1);
  const [seconds, setSeconds] = useState(60);
  const [infoId, setInfoId] = useState('');
  useEffect(() => {
    if (location.state) {
      console.log(location.state);

      sendCodeValidate();
    } else {
      navigate('/register');
    }
  }, []);
  useEffect(() => {
    if (!loadingLogin) {
      if (isLogged) {
        navigate('/');
      }
    }
  }, [isLogged]);
  const sendCodeValidate = async () => {
    const response = await axios<ResponseGeneric<TOtp>>({
      method: 'post',
      url: 'sms/otp/send',
      data: { phone: `${location.state.phone}` },
    });
    response.data?.id
      ? setInfoId(response.data.id)
      : // await register
        // await login dologin(info)
        alert(
          'Ha ocurrido un error en el envio del codigo por favor intente de nuevo en unos minutos'
        );
    console.log(response);

    console.log(infoId);

    // este id lo capturamos en un estado
  };

  const reSendCodeValidate = async () => {
    console.log(infoId);
    const response = await axios<ResponseGeneric<TOtp>>({
      method: 'post',
      url: 'sms/otp/resend',
      data: { id: `${infoId}` },
    });
    console.log(response.data);
    response.data
      ? alert('Se ha enviado un nuevo codigo')
      : // setInfoId(`${response.data?.data.id}`)
        alert('El servicio ha fallado intentelo de nuevo en 1 minuto');

    // este id lo capturamos en un estado
  };

  const sendCode = () => {
    setResend(false);
    setMinuts(1);
    setSeconds(60);

    console.log(
      'enviando codigo, esperar un minuto para reenviar el codigo nuevamente'
    );
    reSendCodeValidate();
  };
  const timer = () => {
    if (minuts > 0) {
      setMinuts(minuts - 1);
    }
    if (minuts === 0 && seconds > 0) {
      setSeconds(seconds - 1);
      console.log(`${seconds}`);
      if (seconds === 1) {
        console.log('ya puedes enviar un codigo nuevo');
        setResend(true);
      }
    }
  };

  useEffect(() => {
    if (!resend) {
      setTimeout(timer, 1000);
    }
  }, [sendCode]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValuesValidate>({
    resolver: yupResolver(schemaPassword),
  });
  const submit = async (data: FormValuesValidate) => {
    console.log(data);

    const response = await axios<ResponseGeneric<Totpverify>>({
      method: 'post',
      url: 'sms/otp/verify',
      data: { id: `${infoId}`, code: data.code },
    });

    if (response.data?.verified == true) {
      const responseRegistro = await axios<ResponseGeneric<User>>({
        method: 'post',
        url: 'users',
        data: {
          firstName: location.state.firstName,
          lastName: location.state.lastName,
          phone: location.state.phone,
          identification: location.state.identification,
          documentTypeId: location.state.documentTypeId,
          password: data.password,
        },
      });
      responseRegistro.data?.id
        ? alert(`bienvenido ${location.state.firstName} `)
        : alert(
            `hubo un error al crear el usuario${responseRegistro.message} `
          );
      // const response = await doPost({
      //   firstName: location.state.firstName,
      //   lastName: location.state.lastName,
      //   phone: location.state.phone,
      //   identification: location.state.identification,
      //   documentTypeId: location.state.documentTypeId,
      //   password: data.password,
      // });
      // console.log(response);
      const datos = {
        identification: location.state.identification,
        password: data.password,
      };
      const responseLogin = await doLogin(datos);
      if (responseLogin.data?.token) {
        setUser(responseLogin.data.user);
        setIsLogged(true);
      } else {
        //Show alert
        alert(responseLogin.message);
      }
    } else {
      alert('Codigo incorrecto');
    }

    // response.data?.data.verified
    //   ? alert('usuario registrado con exito')
    //   : alert('codigo incorrecto ');
  };

  return (
    <div className={styles.Uservalidate}>
      <header className={styles.Uservalidate_header}>
        {/* codigo . {infoId ? infoId : 'Sin codigo'} */}
        <h1 className={styles.Uservalidate_header_h1}>
          {' '}
          Bienvenido {location.state?.firstName
            ? location.state.firstName
            : ''}{' '}
        </h1>
        <p className={styles.Uservalidate_header_pOne}>
          {' '}
          Por favor ingresa el código que te llegó a tu celular y escribe una
          contraseña segura para tu cuenta.{' '}
        </p>
        <p className={styles.Uservalidate_header_pTwo}>
          No ha llegado el código? Espera{' '}
          {minuts
            ? `${minuts}:00`
            : seconds < 10
            ? `0:0${seconds} `
            : `0:${seconds}`}{' '}
          para
          <strong>
            <span
              className={
                resend
                  ? styles.Uservalidate_header_spanOne
                  : styles.Uservalidate_header_spanTwo
              }
              onClick={
                resend
                  ? sendCode
                  : () => {
                      return null;
                    }
              }
            >
              {' '}
              Reenviar
            </span>
          </strong>
        </p>
      </header>
      <div className={styles.Uservalidate_body}>
        <form
          onSubmit={handleSubmit(submit)}
          className={styles.Uservalidate_body_form}
        >
          <Input
            className={styles.Uservalidate_body_form_input}
            type="text"
            label="Código recibido en el celular"
            placeholder="Escriba el codigo que llegó por SMS a su teléfono"
            register={register('code')}
            error={errors.code?.message}
          />
          <Input
            className={styles.Uservalidate_body_form_input}
            type="password"
            label="Contraseñar"
            placeholder="Escriba una contraseña segura"
            register={register('password')}
            error={errors.password?.message}
          />
          <Input
            className={styles.Uservalidate_body_form_input}
            type="password"
            label="Re ingrese la contraseña"
            placeholder="Vuelva a copiar la contraseña"
            register={register('repeatPassword')}
            error={errors.repeatPassword?.message}
          />
          <Button type="submit" theme="primary" size="lg">
            ¡Listo!
          </Button>
        </form>
      </div>
    </div>
  );
};

export default UserValidate;
