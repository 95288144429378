import React, { useContext } from 'react';
import { AppContext } from '../../../routes';
import { getAllDocumentTypes } from '../../../services/documentType.service';
import { doLogout } from '../../../services/login.service';
import { TDocumentType } from '../../../types/documentType.type';
import Button from '../../atoms/button';
import Input from '../../atoms/input';
import Form from '../../molecules/form';
import Modal from '../../molecules/modal';
import Table, { TColumn } from '../../molecules/table';
import './style.module.scss';

const Home = () => {
  const { setIsLogged, setShowModal } = useContext(AppContext);
  const columns: TColumn<TDocumentType>[] = [
    {
      name: 'id',
      label: 'Id',
    },
    {
      name: 'name',
      label: 'Nombre',
      activeSearch: true,
    },
  ];

  const handleLogout = () => {
    doLogout();
    setIsLogged(false);
  };

  const handleChangeQuantity = (value: number | undefined) => {
    console.log(value);
  };

  const handleSelectCheckbox = (values: number[]) => {
    console.log(values);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const optionsShow = [
    {
      id: 1,
      name: 'Ejemplo 1',
    },
    {
      id: 2,
      name: 'Ejemplo 2',
    },
  ];

  const optionsHobbies = [
    {
      id: 1,
      name: 'Futbol',
    },
    {
      id: 2,
      name: 'Baloncesto',
    },
  ];

  return (
    <>
      <Modal
        type="success"
        title="Blog post published"
        handleAction={() => {
          setShowModal(false);
        }}
      >
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Libero, enim illum sequi magnam omnis, et tempore delectus, corporis quia quis repudiandae. Accusantium id officiis quam dolor sint est voluptatem neque!</p>
      </Modal>
      <div className="div">hola</div>
      <Button onClick={handleLogout}>Cerrar Sesión</Button>
      <Button onClick={openModal} theme="warning">
        Abrir Modal
      </Button>
      <Form />
      <br />
      <Table<TDocumentType>
        title="Tipos de documentos"
        getInfo={getAllDocumentTypes}
        columns={columns}
      />
      {/* Ejemplos de uso de Input select, radio y checkbox sin form */}
      <Input
        type="select"
        placeholder="Mostrar"
        options={optionsShow}
        name="select"
        onSelect={(value) => {
          handleChangeQuantity(value);
        }}
      />
      <Input
        type="radio"
        label="Pregunta"
        options={optionsShow}
        name="question"
        onSelect={(value) => {
          handleChangeQuantity(value);
        }}
        value={'1'}
      />
      <Input
        type="checkbox"
        label="Checkbox"
        options={optionsHobbies}
        name="gustos"
        onSelectCheckbox={(values) => {
          handleSelectCheckbox(values);
        }}
      />
    </>
  );
};

export default Home;
