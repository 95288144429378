import { User } from '../types/user.type';
import { getGeneric } from '../services/api.service';

export const getToken = () => {
  const getToken = localStorage.getItem('token') || '';
  let token;
  if (getToken) {
    token = JSON.parse(getToken);
  } else {
    token = '';
  }
  return token;
};

export const checkIsLogged = async () => {
  const token = getToken();
  if (!token) {
    return false;
  }
  const response = await getGeneric<User>('users/me');
  if (!response.data?.id) {
    localStorage.clear();
    return false;
  }
  return true;
};
