import React, { useContext, useRef } from 'react';
import style from './style.module.scss';
import successImg from '../../../assets/icons/icon_success_modal.svg';
import errorImg from '../../../assets/icons/icon_error_modal.svg';
import warningImg from '../../../assets/icons/icon_warning_modal.svg';
import Button from '../../atoms/button';
import Title from '../../atoms/title';
import { AppContext } from '../../../routes';

type TProps = {
  type: 'success' | 'warning' | 'error';
  title: string;
  children?: React.ReactNode;
  handleAction?: () => void;
};

const Modal = ({ children, type, title, handleAction }: TProps) => {
  const { setShowModal, showModal } = useContext(AppContext);
  const ref = useRef<HTMLDivElement>(null);

  const handleClose = (target: EventTarget) => {
    if (target === ref.current) {
      setShowModal(false);
    }
  };

  return (
    <>
      {showModal && (
        <div
          className={style.modal}
          onClick={({ target }) => {
            handleClose(target);
          }}
          ref={ref}
        >
          <div className={style.modal__container}>
            <div
              className={`${style.modal__image__container}`}
              data-theme={type}
            >
              <img
                src={
                  type === 'success'
                    ? successImg
                    : type === 'warning'
                    ? warningImg
                    : errorImg
                }
                alt={type}
              />
            </div>
            <div>
              <Title as="h6">{title}</Title>
            </div>
            <div>{children}</div>
            <div className={style.modal__buttons}>
              <Button
                theme={'neutral'}
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cancelar
              </Button>
              {handleAction && (
                <Button theme={type} onClick={handleAction}>
                  Confirmar
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
