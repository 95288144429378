import React, { useContext, useEffect } from 'react';
import styles from './style.module.scss';
import { useForm } from 'react-hook-form';
import Button from '../../atoms/button';
import Input from '../../atoms/input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import es from 'yup-es';
import rightband3 from '../../../assets/images/Rightband3.png';
import rightband2 from '../../../assets/images/Rightband2.png';
import rightband1 from '../../../assets/images/Rightband1.png';
import logo from '../../../assets/images/oneapp_logo1.png';
import leftband1 from '../../../assets/images/Leftband1.png';
import leftband2 from '../../../assets/images/Leftband2.png';
import { AppContext } from '../../../routes';
import { Link, useNavigate } from 'react-router-dom';
import { FormValuesLogin } from '../../../types/user.type';
import { doLogin } from '../../../services/login.service';
import { schemaLogin } from '../../../schemas/register';
import Loading from '../loading';

yup.setLocale(es);

const Login = () => {
  const navigate = useNavigate();
  const { setUser, setIsLogged, isLogged, loadingLogin } =
    useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValuesLogin>({
    resolver: yupResolver(schemaLogin),
  });

  const submit = async (data: FormValuesLogin) => {
    if (data) {
      const response = await doLogin(data);
      if (response.data?.token) {
        setUser(response.data.user);
        setIsLogged(true);
      } else {
        //Show alert
        alert(response.message);
      }
    }
  };

  useEffect(() => {
    if (!loadingLogin) {
      if (isLogged) {
        navigate('/');
      }
    }
  }, [isLogged]);

  return (
    <>
      {loadingLogin ? (
        <Loading />
      ) : (
        <div className={`${styles.Login}`}>
          <img className={`${styles.Login__ImgRight1}`} src={rightband1} />
          <img className={`${styles.Login__ImgRight2}`} src={rightband2} />
          <img className={`${styles.LoginImgRight3}`} src={rightband3} />
          <img className={`${styles.Loginlogo}`} src={logo} alt="Logo" />
          <img className={`${styles.LoginImgLeft2}`} src={leftband2} />
          <img className={`${styles.LoginImgLeft1}`} src={leftband1} />

          <form onSubmit={handleSubmit(submit)} noValidate>
            <Input
              type="number"
              label="Documento"
              placeholder="Escriba su documento de identidad"
              register={register('identification')}
              error={errors.identification?.message}
            />

            <Input
              type="password"
              label="Contraseña"
              placeholder="********"
              register={register('password')}
              error={errors.password?.message}
            />
            <span className={`${styles.LoginForgetSpan}`}>
              <div className="form-check">
                <input
                  type="checkbox"
                  //Esta parte de aqui la dejo comentada ya que puede servir
                  //para la creacion del input tipo checkbox
                  //pues esta como se validan los errores en el y su cambio
                  //inicio -->
                  // id="selectCheckbox"
                  // {...register('check')}
                  // className={`form-check-input ${
                  //   errors.check ? 'is-invalid' : ''
                  // }`}
                />
                <label htmlFor="check" className={`${styles.LoginRemenber}`}>
                  Recuerdáme
                </label>
                {/* <div className="invalid-feedback">{errors.check?.message}</div> */}
              </div>
              <strong>
                <Link className="redirect-forget" to="/">
                  Olvidaste tu contraseña?
                </Link>
              </strong>
            </span>

            <Button type="submit" theme="primary" size="lg">
              Iniciar sesión
            </Button>
            <p>
              ¿No tienes una cuenta?{' '}
              <strong>
                <Link to="/register">Registrate</Link>
              </strong>
            </p>
          </form>
        </div>
      )}
    </>
  );
};

export default Login;
