import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import Button from '../../atoms/button';
import Input from '../../atoms/input';
import styles from './style.module.scss';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  FormValuesRegisterUser,
  schemaRegisterUser,
} from '../../../schemas/register';
import Title from '../../atoms/title';
import { TDocumentType } from '../../../types/documentType.type';
import { getAllDocumentTypes } from '../../../services/documentType.service';
import { useNavigate } from 'react-router';

const RegisterUsers = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<FormValuesRegisterUser>({
    resolver: yupResolver(schemaRegisterUser),
  });
  const submit: SubmitHandler<FormValuesRegisterUser> = (
    data: FormValuesRegisterUser
  ) => {
    console.log(data);
    navigate('/validate', { state: data });
  };

  const [optionsDocumentType, setOptionsDocumentType] = useState<
    TDocumentType[]
  >([]);

  const getData = async () => {
    const response = await getAllDocumentTypes();
    setOptionsDocumentType(response.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={`${styles.registerUser}`}>
      <header className={`${styles.registerUser_header}`}>
        <Title as="h1">Bienvenido</Title>
        <p className={`${styles.registerUser_header_p}`}>
          Por favor llena estos datos básicos sobre ti para crear tu cuenta.{' '}
        </p>
      </header>
      <div className={`${styles.registerUser_body}`}>
        <form
          onSubmit={handleSubmit(submit)}
          className={`${styles.registerUser_body_form}`}
        >
          <Input
            className={`${styles.registerUser_body_form_inputOne}`}
            type="text"
            label="Nombres"
            placeholder="Escriba su primer y segundo nombre"
            register={register('firstName')}
            error={errors.firstName?.message}
          />

          <Input
            className={`${styles.registerUser_body_form_inputTwo}`}
            type="text"
            label="Apellidos"
            placeholder="Escriba sus apellidos completos"
            register={register('lastName')}
            error={errors.lastName?.message}
          />
          <Input
            className={`${styles.registerUser_body_form_inputThree}`}
            type="select"
            label="Tipo de documento"
            options={optionsDocumentType}
            placeholder="Seleccione una opción"
            setValue={setValue}
            setError={setError}
            register={register('documentTypeId')}
            error={errors.documentTypeId?.message}
          />

          <Input
            className={`${styles.registerUser_body_form_inputFour}`}
            type="number"
            label="Documento"
            placeholder="Escriba su documento de identidad"
            register={register('identification')}
            helpText={'Te enviaremos un código a este número de telefono'}
            error={errors.identification?.message}
          />

          <Input
            className={`${styles.registerUser_body_form_inputFive}`}
            type="number"
            label="Número de celular"
            placeholder="Escriba su número de celular o móvil"
            register={register('phone')}
            error={errors.phone?.message}
          />
          <Button
            className={`${styles.registerUser_body_form_button}`}
            type="submit"
            theme="primary"
            size="lg"
          >
            Registrarme
          </Button>
        </form>
      </div>
    </div>
  );
};

export default RegisterUsers;
