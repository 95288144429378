import * as yup from 'yup';
import es from 'yup-es';
export type FormValuesRegisterUser = {
  firstName: string;
  lastName: string;
  documentTypeId: number;
  identification: string;
  phone: string;
};

//register user
yup.setLocale(es);
const regexCellphone = /3[0-9]{9}/;
const regexDocument = /^(?=.*[0-9]).{5,11}$/;
const regexLetters = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ]+$/;

export const schemaRegisterUser = yup
  .object({
    firstName: yup
      .string()
      .min(3, 'Por favor ingrese un nombre valido ')
      .max(30, 'Por favor ingrese un nombre valido')
      .required('ingrese un nombre válido')
      .matches(regexLetters, { message: 'Introduzca solo letras porfavor' }),
    lastName: yup
      .string()
      .min(4, 'Por favor ingrese sus apellidos ')
      .max(35, 'Por favor no ingrese mas de 35 letras')
      .required()
      .matches(regexLetters, { message: 'Introduzca solo letras porfavor' }),
    documentTypeId: yup
      .number()
      .required('Selecciona una opcion por favor')
      .max(5),
    identification: yup
      .string()
      .required('Ingrese un documento válido')
      .matches(regexDocument, { message: 'Ingrese un documento válido' }),
    phone: yup
      .string()

      .required('Ingrese un telefono por favor sin el identificador del pais')
      .max(10, 'Ingrese un telefono por favor sin el identificador del pais')
      .matches(regexCellphone, {
        message: 'Ingrese un numero de telefono válido de colombia',
      }),
  })
  .required();

//Login
export const schemaLogin = yup
  .object({
    identification: yup
      .number()
      .required('Ingrese su identificación')
      .positive()
      .max(10000000001, 'El numero debe ser Máximo de 11 digitos'),
    password: yup.string().required().max(9),
    // check: yup.bool().oneOf([true], 'Checkbox selection is required')
  })
  .required();

const passworSchema = /^(?=\w*\d)(?=\w*)(?=\w*[a-z])\S{6,16}$/;
export const schemaPassword = yup
  .object({
    code: yup
      .string()
      .required('Porfavor ingresa el código que te llego al celular')
      .min(6, 'Porfavor ingresa el código que te llego al celular')
      .max(7, 'Porfavor ingresa el código que te llego al celular'),
    password: yup
      .string()
      .required('Porfavor ingresa una clave')
      .matches(passworSchema, {
        message:
          'La contraseña debe tener  entre 6 y 16 caracteres, al menos un dígito y al menos una minúscula .',
      })
      .oneOf(
        [yup.ref('repeatPassword')],
        'Las contraseñas ingresadas no coinciden'
      ),
    repeatPassword: yup
      .string()
      .required('Porfavor ingresa una clave')

      .matches(passworSchema, {
        message:
          'La contraseña debe tener  entre 6 y 16 caracteres, al menos un dígito y al menos una minúscula ',
      })
      .oneOf([yup.ref('password')], 'Las contraseñas ingresadas no coinciden'),
  })
  .required();

//validacion parametros de contraseña de registro
// const passwordRegex =
// /^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,10}$/;

// .string()
//   .required("Por favor ingresar contraseña")
//   .min(8, "La contraseña debe contener al menos 8 caracteres")
//   .max(10, "La contraseña debe contener máximo 10 caracteres")
//   .matches(passwordRegex, {
//     message:
//       "La contraseña al menos debe tener un dígito, una minúscula, una mayúscula y al menos un caracter no alfanumérico",
//   })
//minimo 6  caracteres tamaño de 6 entre letras y numeros debe conetener almenos una letra y numeros
