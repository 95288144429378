import React from 'react';
import Title from '../../atoms/title';
import style from './style.module.scss';

type TProps = {
  name: string;
};

const MenuItem = ({ name }: TProps) => {
  return (
    <div className={style.menu__item}>
      <Title as="h4">{name}</Title>
    </div>
  );
};

export default MenuItem;
