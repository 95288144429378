import React from 'react';
import style from './style.module.scss';

type TProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  color?: 'dark' | 'primary';
  children: React.ReactNode;
};

const Title = ({ as = 'h2', color = 'dark', children }: TProps) => {
  switch (as) {
    case 'h1':
      return (
        <h1 className={style.title} data-color={color}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 className={style.title} data-color={color}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 className={style.title} data-color={color}>
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4 className={style.title} data-color={color}>
          {children}
        </h4>
      );
    case 'h5':
      return (
        <h5 className={style.title} data-color={color}>
          {children}
        </h5>
      );
    case 'h6':
      return (
        <h6 className={style.title} data-color={color}>
          {children}
        </h6>
      );

    default:
      return <h2>{children}</h2>;
  }
};

export default Title;
