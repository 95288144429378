import React, { useEffect, useState } from 'react';
import { ResponsePaginate } from '../../../types/responses.type';
import Badge from '../../atoms/badge';
import Button from '../../atoms/button';
import Input from '../../atoms/input';
import Search from '../../atoms/search';
import Title from '../../atoms/title';
import styles from './style.module.scss';

export type TColumn<T> = {
  name: keyof T;
  label: string;
  activeSearch?: boolean;
};

type TProps<T> = {
  title: string;
  getInfo: (
    perPage: number,
    page: number,
    search: string,
    columnSearch: keyof T
  ) => Promise<ResponsePaginate<T>>;
  columns: TColumn<T>[];
};

function Table<T>({ title, columns, getInfo }: TProps<T>) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [isLastPage, setIsLastPage] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState<T[]>();
  const [search, setSearch] = useState('');
  const [columnSearch, setColumnSearch] = useState<keyof T>(columns[0].name);
  const optionsShow = [
    {
      id: 10,
      name: 'Mostrar 10',
    },
    {
      id: 25,
      name: 'Mostrar 25',
    },
    {
      id: 50,
      name: 'Mostrar 50',
    },
  ];

  const getData = async () => {
    const response = await getInfo(perPage, page, search, columnSearch);
    setData(response.data);
    setIsLastPage(response.meta.isLast);
    setTotalPages(response.meta.totalPages);
    setTotalItems(response.meta.totalItems);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };
  const handleChangeQuantity = (value: number | undefined) => {
    if (value) {
      setPerPage(value);
    }
  };

  useEffect(() => {
    const element = columns.find((e) => e.activeSearch);
    if (element) {
      setColumnSearch(element.name);
    }
  }, [columns]);

  useEffect(() => {
    getData();
  }, [page, perPage, search]);

  const handleChangePage = (type: 'next' | 'prev') => {
    if (type === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };
  return (
    <>
      <section className={styles.table__container}>
        <article className={styles.table__header}>
          <div className={styles.table__header__title}>
            <Title as="h6">{title}</Title>
            <Badge>{totalItems} elementos</Badge>
          </div>
          <div>
            <Search handleSearch={handleSearch} />
            {/* Filters */}
          </div>
        </article>
        <table className={styles.table__table} cellSpacing={0} cellPadding={0}>
          <thead className={styles.table__head}>
            <tr>
              {columns.map((nameColumn, index) => (
                <th key={`${nameColumn}_${index}`}>{nameColumn.label}</th>
              ))}
            </tr>
          </thead>
          <tbody className={styles.table__body}>
            {data
              ? data.map((row, index) => (
                  <tr key={index}>
                    {columns.map((column, index2) => (
                      <td key={index2}>{row[column.name] as string}</td>
                    ))}
                  </tr>
                ))
              : null}
          </tbody>
        </table>
        <article className={styles.table__footer}>
          <span className={styles.table__footer__page}>
            Página {page} de {totalPages}
          </span>
          <Input
            className={styles.table__footer__select}
            type="select"
            placeholder="Mostrar"
            options={optionsShow}
            value={perPage.toString()}
            name="select"
            onSelect={(value) => {
              handleChangeQuantity(value);
            }}
          />
          <div className={styles.table__footer__buttons}>
            <Button
              theme="neutral"
              size="sm"
              onClick={() => {
                handleChangePage('prev');
              }}
              disabled={page === 1}
            >
              Anterior
            </Button>
            <Button
              theme="neutral"
              size="sm"
              onClick={() => {
                handleChangePage('next');
              }}
              disabled={isLastPage}
            >
              Siguiente
            </Button>
          </div>
        </article>
      </section>
    </>
  );
}

export default Table;
