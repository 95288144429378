import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import iconSearch from '../../../assets/icons/icon_search.svg';
import useDebounce from '../../../hooks/useDebounce';

type TProps = {
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  handleSearch: (value: string) => void;
};

const Search = ({
  className,
  placeholder = 'Busque algo...',
  disabled,
  handleSearch,
}: TProps) => {
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce<string>(value, 500);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
  };

  useEffect(() => {
    handleSearch(debouncedValue)
  }, [debouncedValue])

  return (
    <div className={style.input__group}>
      <input
        className={`${style.input} ${className || ''}`}
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <img src={iconSearch} alt="IconSearch" className={style.input__icon} />
    </div>
  );
};

export default Search;
